// Nodes modules
import './modules/@tc';
//import './datepicker/datepicker-full.min.js';
//import './datepicker/locales/fr.js';
import 'classlist-polyfill';
import 'object-fit-polyfill';
//import animateScrollTo from 'animated-scroll-to';
//import * as Swiper from 'swiper/dist/js/swiper.js';
//import * as Stickyfill from 'stickyfilljs';

import Datepicker from 'vanillajs-datepicker/Datepicker';
import DateRangePicker from 'vanillajs-datepicker/DateRangePicker';
import fr from 'vanillajs-datepicker/locales/fr';

import Collapse from 'bootstrap/js/dist/alert';

Object.assign(Datepicker.locales, fr);

window.addEventListener('load', function(){   
    // Menu Open - Open main nav
    var body = document.body;
    var burgerBtn = document.getElementById("burger-btn");

    function toggleMainNav() {
        body.classList.toggle("menu-open");
    } 
    
    if (typeof(burgerBtn) != 'undefined' && burgerBtn != null) {
        burgerBtn.onclick = toggleMainNav;
    }

    // Match window size function
    function installMediaQueryWatcher(mediaQuery, layoutChangedCallback) {
        var mql = window.matchMedia(mediaQuery);
        mql.addListener(function (e) { return layoutChangedCallback(e.matches); });
        layoutChangedCallback(mql.matches);
    } 

    // General - Nav sticky
    var stickyNav = document.getElementById("fixedHeader");
                    
    /* Gets the amount of height of the element from the viewport and adds the pageYOffset to get the height relative to the page */
    var currNavStickyPos = stickyNav.getBoundingClientRect().top + window.pageYOffset + 50;
    
    installMediaQueryWatcher("(min-width: 992px)", function(matches) {
        if (matches) {

            function checkStickyNav() {     
            /* Check if the current Y offset is greater than the position of the element */
            if(window.pageYOffset > currNavStickyPos) {
                    stickyNav.classList.add("sticky-nav");
                } else {
                    stickyNav.classList.remove("sticky-nav");
                } 
            }

            function fixedStickyNav() {     
                currNavStickyPos = stickyNav.getBoundingClientRect().top + window.pageYOffset;
                stickyNav.classList.add("sticky-nav");
            }

            checkStickyNav();

            window.onscroll = checkStickyNav;

            window.onresize = fixedStickyNav;
        }
    });


    var reservationForm = document.getElementById("reservation-form");
    if (typeof(reservationForm) != 'undefined' && reservationForm != null) {
        // Form - General
        var today = new Date();
        var startDatepickerInput = document.querySelector('.form-item-start');
        var endDatepickerInput = document.querySelector('.form-item-end');
        var startDatepickerLabel = document.querySelector('.js-form-item-start');
        var endDatepickerLabel = document.querySelector('.js-form-item-end');
        var errorRangeMessage = document.getElementById('error-form-message');
        var errorHighMessage = document.getElementById('high-error-form-message');
        var datepickerStart = document.getElementById("edit-start");
        var datepickerEnd = document.getElementById("edit-end");
        var homepageForm = document.getElementById('homepageForm');
        var generalForm = document.getElementById('edit-radio-packs--wrapper');
        var reducInput = document.getElementById('edit-reduc');


        // Form - Range - Disabled Date
        var disabledDate = [];
        var calcDisable = [];

        var getDaysArray = function(s,e) {
            for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ 
                var rangeDisabled = ("0" + d.getDate()).slice(-2)+'/'+(("0" + (d.getMonth() + 1)).slice(-2))+'/'+d.getFullYear();
                
                a.push(rangeDisabled);
            } return a;
        };

        Array.from(document.querySelectorAll('#disabledInput .disabled-date-container')).forEach((disabledDateContainer) => {
            var disableDateStart = disabledDateContainer.querySelector('.disabled-start-date');
            var disableDateEnd = disabledDateContainer.querySelector('.disabled-end-date');
        
            disabledDate = getDaysArray(new Date(disableDateStart.innerHTML),new Date(disableDateEnd.innerHTML));
            calcDisable.push(...disabledDate);
        });


        // Form - Submit focus
        var formSubmitHomepage = document.getElementById('edit-actions-submit');
        var formSubmitReservation = document.getElementById('edit-actions-01-submit');
        var formSubmitParent = document.getElementById("edit-actions");

        if (typeof(formSubmitHomepage) != 'undefined' && formSubmitHomepage != null) {
            formSubmitHomepage.addEventListener("focus", onFocus);
            formSubmitHomepage.addEventListener("focusout", onLostFocus);
        }

        if (typeof(formSubmitReservation) != 'undefined' && formSubmitReservation != null) {
            formSubmitReservation.addEventListener("focus", onFocus);
            formSubmitReservation.addEventListener("focusout", onLostFocus);
        }

        function onFocus() {
            formSubmitParent.classList.add('hard-focus')
        }

        function onLostFocus() {
            formSubmitParent.classList.remove('hard-focus')
        }

        
        // Form - Range input - Today as default value in start date. 
        var addTodayDay = 0;
        var todayDate = ("0" + today.getDate()).slice(-2)+'/'+(("0" + (today.getMonth() + 1)).slice(-2))+'/'+today.getFullYear();

        var addTommorowDay = 1;
        var tommorow = new Date(todayDate.split('/')[2],todayDate.split('/')[1]-1,todayDate.split('/')[0]);
        var tommorowDate = (tommorow.getDate() + addTommorowDay)+'/'+(("0" + (tommorow.getMonth() + 1)).slice(-2))+'/'+tommorow.getFullYear();

        for (var r = 0; r < calcDisable.length; r++) {
            while (calcDisable.includes(todayDate) == true || calcDisable.includes(tommorowDate) == true) {
                addTodayDay++;

                todayDate = ("0"+(today.getDate() + addTodayDay)).slice(-2)+'/'+(("0" + (today.getMonth() + 1)).slice(-2))+'/'+today.getFullYear();                

                tommorow = new Date(todayDate.split('/')[2],todayDate.split('/')[1]-1,todayDate.split('/')[0]);

                tommorowDate = (tommorow.getDate() + 1)+'/'+(("0" + (tommorow.getMonth() + 1)).slice(-2))+'/'+tommorow.getFullYear();
            } 
        }

        var addTodayFixDay = 0;

        if((("0" + (today.getMonth() + 1)) == "08") && (("0"+(today.getDate() + addTodayDay)).slice(-2) == "32")) {            
            todayDate = ("01").slice(-2)+'/'+(("0" + (today.getMonth() + 2)).slice(-2))+'/'+today.getFullYear();

            var todayFix = new Date(todayDate.split('/')[2],todayDate.split('/')[1]-1,todayDate.split('/')[0]);

            tommorowDate = ("02").slice(-2)+'/'+(("0" + (today.getMonth() + 2)).slice(-2))+'/'+today.getFullYear();

            var tommorowFix = new Date(tommorowDate.split('/')[2],tommorowDate.split('/')[1]-1,tommorowDate.split('/')[0]);

            for (var r = 0; r < calcDisable.length; r++) {
                while (calcDisable.includes(todayDate) == true || calcDisable.includes(tommorowDate) == true) {
                    addTodayFixDay++;
    
                    todayDate = ("0"+(todayFix.getDate() + addTodayFixDay)).slice(-2)+'/'+(("0" + (todayFix.getMonth() + 1)).slice(-2))+'/'+todayFix.getFullYear();         

                    tommorowDate = ("0"+(tommorowFix.getDate() + addTodayFixDay)).slice(-2)+'/'+(("0" + (tommorowFix.getMonth() + 1)).slice(-2))+'/'+tommorowFix.getFullYear();         
                }
            }           
        }

        //console.log(todayDate);
        //console.log(tommorowDate);

        
        // Form - Range input - Datepicker
        const elem = document.getElementById('range');
        const dateRangePicker = new DateRangePicker(elem, {
            // Whether or not to close the datepicker immediately when a date is selected.
            //autohide: true,
            
            // Array of date strings or a single date string formatted in the given date format.
            datesDisabled: calcDisable,

            // The date format, combination of d, dd, D, DD, m, mm, M, MM, yy, yyyy.
            language: 'fr',
             
            // Maximum limit to the view that the date picker display. 0:days – 3:decades.
            maxView: 0,

            //The date to be focused when the date picker opens with no selected date(s).
            defaultViewDate: todayDate,

            // Minimum limit to selectable date. No limit is applied if null is set.
            minDate: today,

            //Whether to highlight the current date.
            todayHighlight: true,

            // Minimum limit to selectable date. No limit is applied if null is set.
            //clearBtn: 'true',
            
            //Whether to allow one side of the date-range to be blank.
            //allowOneSidedRange: true,

            
            //orientation: "bottom left",
        });

        var testEmptyDate =  document.getElementById('edit-start').value;
        if (typeof(testEmptyDate) != 'undefined' && testEmptyDate != null && testEmptyDate == '') {   
            dateRangePicker.setDates(todayDate, tommorowDate);
        }
        

        // Form - Range - Test if today is just before a disabled day
        function testToday() {
            var testStart = document.querySelector('.js-form-item-start .today');
            var testEnd = document.querySelector('.js-form-item-end .today');
            
            if(typeof(testStart) != 'undefined' && testStart != null && testStart.nextSibling.classList.contains('disabled')) {
                testStart.classList.add('today-disabled');
            } 

            if(typeof(testEnd) != 'undefined' && testEnd != null && testEnd.nextSibling.classList.contains('disabled')) {
                testEnd.classList.add('today-disabled');
            }
        }

        testToday();

        // Form - Range - Test if disabled days are just before a disabled day
        var testDisabledAll = [];

        function testDisabledRange() {
            testDisabledAll = Array.from(document.querySelectorAll('.datepicker-cell.disabled'));

            //console.log(testDisabledAll);
            
            testDisabledAll.forEach(function(item){
                if(typeof(item) != 'undefined' && item != null && typeof(item.nextElementSibling) != 'undefined' && item.nextElementSibling != null && item.nextElementSibling.classList.contains('disabled')) {
                    item.classList.add('disabled-in-range');
                } else {
                    item.classList.add('last-disabled-in-range');
                }     
            });
            
        }

        testDisabledRange();
        
        // Form - Homepage form
        installMediaQueryWatcher("(max-width: 575px)", function(matches) {
            if (matches) {
                if (typeof(homepageForm) != 'undefined' && homepageForm != null) {   
                    dateRangePicker.setOptions( {
                        allowOneSidedRange: true,
                        orientation: "bottom left",
                    });

                    dateRangePicker.setDates({clear: true}, {clear: true});
                    //dateRangePicker.setDates(todayDate, {clear: true});

                    document.getElementById('edit-end').value = tommorowDate;

                    datepickerStart.addEventListener('changeDate', function (evt) {    
                        var selectedRangeDates = dateRangePicker.getDates('dd/mm/yyyy');
                        var firstSelectedRangeDate = selectedRangeDates[0];

                        tommorow = new Date(firstSelectedRangeDate.split('/')[2],firstSelectedRangeDate.split('/')[1]-1,firstSelectedRangeDate.split('/')[0]);

                        tommorowDate = (tommorow.getDate() + 1)+'/'+(("0" + (tommorow.getMonth() + 1)).slice(-2))+'/'+tommorow.getFullYear();
                            
                        document.getElementById('edit-end').value = tommorowDate;
                        //dateRangePicker.setDates(todayDate, tommorowDate);
                    });

                    testToday();                    
                }

                if (typeof(generalForm) != 'undefined' && generalForm != null) {
                    dateRangePicker.setOptions( {
                       orientation: "bottom left",
                   });
                }
            }
        });
       

        // Form - Range - Test error position
        var datepickerAll = document.querySelectorAll('.datepicker-input');
        var datepickerDropdown;
        
        [].forEach.call(datepickerAll, function(div) {
            var datepickerDropdown = div.nextElementSibling;

            div.addEventListener('show', function (evt) {         
                if(datepickerDropdown.classList.contains('datepicker-orient-top')) {
                    errorRangeMessage.classList.add('bottom-error');
                    errorHighMessage.classList.add('bottom-error');
                } else {
                    errorRangeMessage.classList.remove('bottom-error');
                    errorHighMessage.classList.remove('bottom-error');
                }
            });
        });
    

        // Form - Range input - Disabled past date
        function disabledPast() {   
            var pastDisabledStart = Array.from(document.querySelectorAll('.js-form-item-start .datepicker-cell.disabled'));
            var pastDisabledEnd = Array.from(document.querySelectorAll('.js-form-item-end .datepicker-cell.disabled'));

            var startFocused = document.querySelector('.js-form-item-start .today');
            var endFocused = document.querySelector('.js-form-item-end .today');
            
            if (typeof(startFocused) != 'undefined' && startFocused != null) {
                pastDisabledStart.forEach(function(item){
                    if (item.compareDocumentPosition(startFocused) & Node.DOCUMENT_POSITION_FOLLOWING) {
                        item.classList.add('past-disabled');
                    } 
                });
            }

            if (typeof(endFocused) != 'undefined' && endFocused != null) {
                pastDisabledEnd.forEach(function(item){
                    if (item.compareDocumentPosition(endFocused) & Node.DOCUMENT_POSITION_FOLLOWING) {
                        item.classList.add('past-disabled');
                    } 
                });
            }
        }
        disabledPast();

        datepickerStart.addEventListener('changeMonth', function (evt) {
            setTimeout(function() {         
                disabledPast();
                testToday();
                testDisabledRange();
            }, 1);
        });

        datepickerEnd.addEventListener('changeMonth', function (evt) {         
            setTimeout(function() {         
                disabledPast();
                testToday();
                testDisabledRange();
            }, 1);
        });

        
        // Form - Range input - Test disabled date
        function testedDisabledDates() {
            var selectedRangeDates = dateRangePicker.getDates('dd/mm/yyyy');
            var firstSelectedRangeDate = selectedRangeDates[0];
            var secondSelectedRangeDate = selectedRangeDates[1];

            var dateFrom = firstSelectedRangeDate;
            var dateTo = secondSelectedRangeDate;
   
            var d1 = dateFrom.split("/");
            var d2 = dateTo.split("/");
                
            var from = new Date(d1[2], parseInt(d1[1])-1, d1[0]);  // -1 because months are from 0 to 11
            var to   = new Date(d2[2], parseInt(d2[1])-1, d2[0]);

            for (var i = 0; i < calcDisable.length; i++) {
                var disabledDateData = calcDisable[i];

                var dateCheck = disabledDateData;
                var c = dateCheck.split("/");

                var check = new Date(c[2], parseInt(c[1])-1, c[0]);
                var datepickCelRange = Array.from(document.querySelectorAll('.datepicker-cell.range'));

                if(check > from && check < to) {
                     //console.log('not-good');
                    errorRangeMessage.classList.remove('hide');
                    formSubmitParent.classList.add('hide');
                    startDatepickerLabel.classList.add('hide');
                    endDatepickerLabel.classList.add('hide');
                    //dateRangePicker.refresh;
                    dateRangePicker.update;
                    datepickCelRange.forEach(element => element.classList.add("reserved"));
                    break;
                } else {
                    errorRangeMessage.classList.add('hide');
                    formSubmitParent.classList.remove('hide');
                    startDatepickerLabel.classList.remove('hide');
                    endDatepickerLabel.classList.remove('hide');
                    //console.log('good');
                    datepickCelRange.forEach(element => element.classList.remove("reserved"));
                }
            }
        }      

        // Form - Homepage form
        if (typeof(homepageForm) != 'undefined' && homepageForm != null) {   
            installMediaQueryWatcher("(min-width: 576px)", function(matches) {
                if (matches) {
                    datepickerStart.addEventListener('changeDate', function (evt) {         
                         testedDisabledDates();
                    });
                }
            });
        }
    
                
        // Form - Range input - Add default value to input
        var inputStartValue = document.getElementById('edit-start').value;
        if (inputStartValue.length == 0) {
            document.getElementById("edit-start").value = todayDate;
        
            var startRangeStartFocused = document.querySelector('.js-form-item-start .focused');
            startRangeStartFocused.classList.add("selected","range-start", "range-end");
        }
   
        var inputEndValue = document.getElementById('edit-end').value;
        if (inputEndValue.length == 0) {
            document.getElementById("edit-end").value = todayDate;

            var endRangeStartFocused = document.querySelector('.js-form-item-end .focused');
            endRangeStartFocused.classList.add("selected","range-start", "range-end");
        }


        // Form - General form
        if (typeof(generalForm) != 'undefined' && generalForm != null) {
            // Form - Test disabled on change date
            datepickerStart.addEventListener('changeDate', function (evt) {   
                //testHighMonth();      
                testedDisabledDates();
                testSeasonPrice();
                packResume();
            });

            datepickerEnd.addEventListener('changeDate', function (evt) {         
                //testHighMonth();
                testedDisabledDates();
                testSeasonPrice();
                packResume();
            });

            // Form - Sidebar - Sticky
            var stickyElem = document.getElementById("edit-sidebar-column");
            var currStickyPos = stickyElem.getBoundingClientRect().top + window.pageYOffset - 400;

            window.onscroll = function() {
                installMediaQueryWatcher("(min-width: 1200px) and (min-height: 750px)", function(matches) {
                    if (matches) {
                        if(window.pageYOffset > currStickyPos) {
                            stickyElem.style.position = "sticky";
                            stickyElem.style.top = "105px";
                            stickyElem.classList.add("sticky-sidebar");
                        } else {
                            stickyElem.style.position = "relative";
                            stickyElem.style.top = "initial";
                            stickyElem.classList.remove("sticky-sidebar");
                        }
                    }
                });

                installMediaQueryWatcher("(max-width: 1199px)", function(matches) {
                    if (matches) {
                        if(window.pageYOffset > currStickyPos) {
                            stickyElem.style.position = "relative";
                            stickyElem.style.bottom = "initial";
                            stickyElem.classList.remove("sticky-sidebar");
                        } else {
                            stickyElem.style.position = "fixed";
                            stickyElem.style.bottom = "0px";
                            stickyElem.classList.add("sticky-sidebar");
                        }
                    }
                });

                /* Check if the current Y offset is greater than the position of the element */
                if(window.pageYOffset > currNavStickyPos) {
                    stickyNav.classList.add("sticky-nav");
                } else {
                    stickyNav.classList.remove("sticky-nav");
                }
            }   


            installMediaQueryWatcher("(max-width: 1199px)", function(matches) {
                if (matches) {
                    stickyElem.style.position = "fixed";
                    stickyElem.style.bottom = "0px";
                    stickyElem.classList.add("sticky-sidebar");
                }
            });

            // Form - Sidebar - Resume date
            var dateStartInputValue = document.getElementById('edit-start').value;
            var dateEndInputValue = document.getElementById('edit-end').value;
            var months = ["Jan", "Fév", "Mars", "Avril", "Mai", "Juin", "Jui", "Aoùt", "Sep", "Oct", "Nov", "Dec"];
       
            var testPromo = false;

            // Form - Sidebar - Resume date Start
            function resumeStartDate() {
                dateStartInputValue = document.getElementById('edit-start').value;

                var testDayStart = dateStartInputValue.split('/')[0];
                var testDayEnd = dateEndInputValue.split('/')[0];
                var testMonthStart = dateStartInputValue.split('/')[1];
                var testYearStart = dateStartInputValue.split('/')[2];

                var newStartYearInput = document.getElementById('edit-startyear');

                newStartYearInput.value = testYearStart.slice(-2);

                //console.log(newStartYearInput.value);

                //console.log(testMonthStart);

                //console.log(diffDays);

                // Add promo
                //console.log(testDayEnd);
                //console.log(testMonthStart);
                
                /*
                PROMO WITH RESERVATION DATE ON XX/XX
                
                if (testMonthStart == "01" && testYearStart == "2023") {
                    testPromo = true;
                    //console.log(testPromo);
                } else {
                    testPromo = false;
                    //console.log(testPromo);
                }
                */

                /*
                PROMO WITH TODAY DATE XX/XX
                */
                if (
                    todayDate == "1/03/2023" ||
                    todayDate == "2/03/2023" ||
                    todayDate == "3/03/2023" ||
                    todayDate == "4/03/2023" ||
                    todayDate == "5/03/2023" ||
                    todayDate == "6/03/2023" ||
                    todayDate == "7/03/2023" ||
                    todayDate == "8/03/2023" ||
                    todayDate == "9/03/2023" ||
                    todayDate == "01/03/2023" ||
                    todayDate == "02/03/2023" ||
                    todayDate == "03/03/2023" || 
                    todayDate == "04/03/2023" ||
                    todayDate == "05/03/2023" ||
                    todayDate == "06/03/2023" || 
                    todayDate == "07/03/2023" ||
                    todayDate == "08/03/2023" ||
                    todayDate == "09/03/2023" || 
                    todayDate == "10/03/2023" ||
                    todayDate == "11/03/2023" ||
                    todayDate == "12/03/2023" ||
                    todayDate == "13/03/2023" ||
                    todayDate == "14/03/2023" ||
                    todayDate == "15/03/2023") {
                    //console.log('yes');
                    testPromo = true;
                } else {
                    //console.log('no');
                    testPromo = false;
                }
                

                var getMonthNameStart = function(fullName){
                    var monthNameStart = months[dateStartInputValue.split('/')[1].replace(/^0+/, '') - 1]
                    return fullName ? monthNameStart : monthNameStart.substring(0, 3);
                }

                var resumeDateStartDay = document.getElementById('start_resume_day');
                resumeDateStartDay.innerHTML = testDayStart;

                var resumeDateStartMonth = document.getElementById('start_resume_month');
                resumeDateStartMonth.innerHTML = getMonthNameStart(true);

                var resumeDateStartYear = document.getElementById('start_resume_year');
                resumeDateStartYear.innerHTML = testYearStart;

                numberOfDays();

                if ((testMonthStart == '07' || testMonthStart == '08') && diffDays < 3) {
                    formSubmitParent.classList.add('hide');
                    errorHighMessage.classList.remove('hide');
                } else {
                    errorHighMessage.classList.add('hide');
                    formSubmitParent.classList.remove('hide');
                }
            }
            resumeStartDate();

            datepickerStart.addEventListener('changeDate', function (evt) {         
                resumeStartDate();
                //numberOfDays();
                optionPrices();
                optionResume();
                //getStartYeart();
            });

      

            // Form - Sidebar - Resume date End
            function resumeEndDate() {
                dateEndInputValue = document.getElementById('edit-end').value;

                var testDayEnd = dateEndInputValue.split('/')[0];
                var testMonthEnd = dateEndInputValue.split('/')[1];
                var testYearEnd = dateEndInputValue.split('/')[2];

                var getMonthNameEnd = function(fullName){
                    var monthNameEnd = months[dateEndInputValue.split('/')[1].replace(/^0+/, '') - 1]
                    return fullName ? monthNameEnd : monthNameEnd.substring(0, 3);
                }

                var resumeDateEndDay = document.getElementById('end_resume_day');
                resumeDateEndDay.innerHTML = testDayEnd;

                var resumeDateEndMonth = document.getElementById('end_resume_month');
                resumeDateEndMonth.innerHTML = getMonthNameEnd(true);

                var resumeDateEndYear = document.getElementById('end_resume_year');
                resumeDateEndYear.innerHTML = testYearEnd;

                numberOfDays();

                var newDateStartInputValue = document.getElementById('edit-start').value;

                var newTestMonthStart = newDateStartInputValue.split('/')[1];

                //console.log('high');

                if ((newTestMonthStart == '07' || newTestMonthStart == '08') && diffDays < 3) {
                    errorHighMessage.classList.remove('hide');
                    formSubmitParent.classList.add('hide');
                } else {
                    errorHighMessage.classList.add('hide');
                    formSubmitParent.classList.remove('hide');
                }
            }
            resumeEndDate();

            datepickerEnd.addEventListener('changeDate', function (evt) {         
                resumeEndDate();
                //numberOfDays();
                optionPrices();
                optionResume();
            });


            // Form - Radio
            Array.from(document.querySelectorAll('#edit-horaire--wrapper .form-check')).forEach((formHoraireStartRadio) => {
                var formHoraireStartInput = formHoraireStartRadio.querySelector('.form-radio');
                formHoraireStartInput.closest('.form-check').prepend(formHoraireStartInput);
            });
            
            Array.from(document.querySelectorAll('#edit-radio-packs--wrapper .form-check')).forEach((formCheckRadio) => {
                var formRadioInput = formCheckRadio.querySelector('.form-radio');
                formRadioInput.closest('.form-check').prepend(formRadioInput);

                formRadioInput.addEventListener("click", packResume);
                formRadioInput.addEventListener("click", totalAmount);
            });

            var packBasicInput = document.getElementById('edit-radio-packs-basic');
            var packBasicLabel = packBasicInput.nextElementSibling;
            var packBasicSpan = document.createElement('span');
            packBasicSpan.innerHTML = '99€ par jour';
            packBasicLabel.append(packBasicSpan);

            var packAventureInput = document.getElementById('edit-radio-packs-aventure');
            var packAventureLabel = packAventureInput.nextElementSibling;
            var packAventureSpan = document.createElement('span');
            packAventureSpan.innerHTML = '114€ par jour';
            packAventureLabel.append(packAventureSpan);

            var packNomadeInput = document.getElementById('edit-radio-packs-nomade');
            var packNomadeLabel = packNomadeInput.nextElementSibling;
            var packNomadeSpan = document.createElement('span');
            packNomadeSpan.innerHTML = '129€ par jour';
            packNomadeLabel.append(packNomadeSpan);


            // Form - Test basse/haute saison
            var currentYear = new Date().getFullYear();
            var inputStartValueTest;
            var booleanH;


            function testSeasonPrice() {

                inputStartValueTest = document.getElementById('edit-start').value;

                var splitInputStartValueTest = inputStartValueTest.split("/");
                var testCurrentYear = splitInputStartValueTest[2];

                //console.log(testCurrentYear);

                var dateFromHaute = "31/03/" + testCurrentYear;
                var dateToHaute = "31/09/" + testCurrentYear;
                var dateCheckHaute = inputStartValueTest;

                var dH1 = dateFromHaute.split("/");
                var dH2 = dateToHaute.split("/");
                var cH = dateCheckHaute.split("/");

                var fromH = new Date(dH1[2], parseInt(dH1[1])-1, dH1[0]);  // -1 because months are from 0 to 11
                var toH   = new Date(dH2[2], parseInt(dH2[1])-1, dH2[0]);
                var checkH = new Date(cH[2], parseInt(cH[1])-1, cH[0]);

                if(checkH > fromH && checkH < toH) {
                    //console.log('Haute Saison');
                    packBasicSpan.innerHTML = '130€ par jour';
                    packAventureSpan.innerHTML = '145€ par jour';
                    packNomadeSpan.innerHTML = '160€ par jour';
                    booleanH = true;
                }

                else {
                    //console.log('Basse Saison');
                    packBasicSpan.innerHTML = '94€ par jour';
                    packAventureSpan.innerHTML = '109€ par jour';
                    packNomadeSpan.innerHTML = '124€ par jour';
                    booleanH = false;
                }
            }

            testSeasonPrice();

            Array.from(document.querySelectorAll('#edit-radio-packs--wrapper .form-check')).forEach((formCheckRadio) => {
                var radioSubcontainer = document.createElement('div');
                radioSubcontainer.classList.add('radio-subcontainer');
                formCheckRadio.appendChild(radioSubcontainer);
                var formRadioLabel = formCheckRadio.querySelector('.form-check-label');
                var formRadioDesc = formCheckRadio.querySelector('.description');
                radioSubcontainer.append(formRadioLabel, formRadioDesc);
            });            
            
            
            // Form - Sidebar - Resume Pack
            var packResumeValue;
            function packResume() {
                var packResumeText = document.getElementById('pack_resume');
                var kmResume = document.getElementById('km_resume');

                if (packBasicInput.checked) {
                    packResumeText.innerHTML = 'Pack Basic';
                    kmResume.innerHTML = '200 km/jour';

                    if (booleanH == true) {
                        packResumeValue = 130;
                    } else {
                        packResumeValue = 94; 
                    }
                }

                if (packAventureInput.checked) {
                    packResumeText.innerHTML = 'Pack Adventure';
                    kmResume.innerHTML = '300 km/jour';            
        
                    if (booleanH == true) {
                        packResumeValue = 145;
                    } else {
                        packResumeValue = 109;
                    }
                }

                if (packNomadeInput.checked) {
                    packResumeText.innerHTML = 'Pack Nomad';
                    kmResume.innerHTML = 'km illimité';     
               
                    if (booleanH == true) {
                        packResumeValue = 160;
                    } else {
                        packResumeValue = 124;
                    }
                }
            }
            packResume();
            
            // Form - Sidebar - Reception Time
            var receptionTimeNight = false;
            function receptionResume() {
                var receptionChecked = document.querySelector('#edit-horaire--wrapper .form-radio:checked');
                var receptionValueChecked = receptionChecked.value;
                //console.log(receptionValueChecked);
 
                if (receptionValueChecked == "entre 17h00 et 19h00") {
                    receptionTimeNight = true;
                } else {
                    receptionTimeNight = false;
                }
            }  

            receptionResume();

            Array.from(document.querySelectorAll('#edit-horaire--wrapper .form-check')).forEach((formRadioReceptionBox) => {
                var formReceptionInput = formRadioReceptionBox.querySelector('.form-radio');
                formReceptionInput.addEventListener("click", receptionResume);
                formReceptionInput.addEventListener("click", totalAmount);
            })



            // Form - Sidebar - Resume date Number
            var diffDays;

            function numberOfDays() {
                var dateStartInputCalc = dateStartInputValue;
                dateStartInputCalc = new Date(dateStartInputCalc.split('/')[2],dateStartInputCalc.split('/')[1]-1,dateStartInputCalc.split('/')[0]);
                var dateEndInputCalc = dateEndInputValue;
                dateEndInputCalc = new Date(dateEndInputCalc.split('/')[2],dateEndInputCalc.split('/')[1]-1,dateEndInputCalc.split('/')[0]);
                var timeDiff = Math.abs(dateEndInputCalc.getTime() - dateStartInputCalc.getTime());
                diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;  
                //console.log(diffDays);

                var addS = document.getElementById('total_resume_month');

                if (diffDays == 0 || diffDays == 1) {
                    addS.innerHTML = "jour";
                } else {
                    addS.innerHTML = "jours";
                }
                        
                var resumeTotalDay = document.getElementById('total_resume_day');
                resumeTotalDay.innerHTML = diffDays;

                totalAmount();


                
            }
            numberOfDays();


            // Form - Sidebar - Option price
            var totalOption;
            var optionsAll = document.querySelectorAll('#edit-checkbox-options .form-check');
            var optionsValue;

            for (var i = 0; i < optionsAll.length; i++) {
                var optionsValue = optionsAll[i].querySelector('.option');
                optionsAll[i].querySelector('.form-checkbox').setAttribute("data-subprice", optionsValue.innerHTML);

                if (optionsAll[i].querySelector('.form-checkbox').id == "edit-checkbox-options-tente-de-douche") {
                    optionsAll[i].querySelector('.form-checkbox').setAttribute("original-data-subprice", optionsValue.innerHTML);
                    var priceByDay = optionsAll[i].querySelector('.form-checkbox').getAttribute("original-data-subprice");
                    optionsAll[i].querySelector('.option').innerHTML = optionsAll[i].querySelector('.option').innerHTML + '€/jour';
                    optionsAll[i].querySelector('.form-checkbox').setAttribute("data-subprice", (priceByDay*diffDays));
                    //console.log(optionsAll[i].getAttribute("data-subprice"));
                } else if (optionsAll[i].querySelector('.form-checkbox').id == "edit-checkbox-options-forfait-nettoyage") {
                } else {
                    optionsAll[i].querySelector('.option').innerHTML = optionsAll[i].querySelector('.option').innerHTML + '€';
                }
            }

            var cleaningPrice = 0;
            var cleaningPriceValue = 0;

            function optionPrices() {
                var optionsChecked = document.querySelectorAll('#edit-checkbox-options .form-checkbox:checked');
                totalOption = parseInt(cleaningPriceValue);
            
                for (var i = 0; i < optionsChecked.length; i++) {
                    if (optionsChecked[i].id == "edit-checkbox-options-tente-de-douche") {
                        optionsChecked[i].setAttribute("data-subprice", (priceByDay*diffDays));
                    }

                    totalOption = totalOption + parseInt(optionsChecked[i].getAttribute("data-subprice"));
                }
            }
            optionPrices();

       
            
            // Form - Sidebar - Total amount
            var totalCalcInput;
            var totalCautionInput;
            

            function totalAmount() {      
                optionPrices();
                
                totalCalcInput = document.getElementById('edit-totalvalue');
                totalCautionInput = document.getElementById('edit-cautionvalue');
                var currentDateValue = document.getElementById('edit-currentdatevalue');
                var totalCalcText = document.getElementById('total_calc');
                var totalDays = parseInt(diffDays, 10);
                totalCalcText.innerHTML = totalCalc + '€';

                if (diffDays <= 1) {
                    var totalCalc = (totalDays + 1)*packResumeValue + totalOption;
                } else {
                    var totalCalc = totalDays*packResumeValue + totalOption;
                }


                if (receptionTimeNight == true) {
                    var totalCalcBeforeRound = Math.round(totalCalc - (packResumeValue/3.3333)/5 * 5);
                    totalCalc = Math.round(totalCalcBeforeRound/5) * 5;
                }

                var totalLabel = document.querySelector('#total_calc_container label');


                // TEST DE REDUC TEST
                var oldTotalCalc = totalCalc;
                var code = 'roadbooking10';

                totalLabel.classList.add('label-promoted');
                var labelPromoted = document.querySelector('.label-promoted');
                
                reducInput.addEventListener("input", function(event) {
                    //console.log(oldTotalCalc);
                    if (diffDays < "08") {
                        if(reducInput.value == code) {
                            totalCalc = Math.round(totalCalc - (totalCalc/100) * 10);
                            totalCalcText.innerHTML = totalCalc + '€';
                            labelPromoted.innerHTML = "Total <span class='total-calc-days-responsive'> | " + diffDays + " jours</span>" + " (-10%)";

                        } else {
                            totalCalc = oldTotalCalc;
                            totalCalcText.innerHTML = oldTotalCalc + '€';
                            totalLabel.classList.remove('label-promoted');
                            totalLabel.innerHTML = "Total <span class='total-calc-days-responsive'>| " + diffDays + " jours</span>";
                        }
                    }
                });

                                 
                //PROMO 7 DAYS BOOKED
                var testSevenDaysPromo = false;

                if (diffDays >= "08") {
                    testSevenDaysPromo = true;
                    //console.log(testSevenDaysPromo);
                } else {
                    testSevenDaysPromo = false;
                    //console.log(testSevenDaysPromo);

                    if(reducInput.value == code) {
                        totalCalc = Math.round(totalCalc - (totalCalc/100) * 10);
                        totalCalcText.innerHTML = totalCalc + '€';
                        labelPromoted.innerHTML = "Total <span class='total-calc-days-responsive'> | " + diffDays + " jours</span>" + " (-10%)";
                    } 
                }

                if(testSevenDaysPromo == true) {
                    totalCalc = Math.round(totalCalc - (totalCalc/100) * 10);
                    totalCalcInput = document.getElementById('edit-totalvalue');

                    /*
                    if (labelPromoted.innerHTML.indexOf("(-10%)") != -1) {
                        labelPromoted.innerHTML = "Total <span class='total-calc-days-responsive'> | " + diffDays + " jours</span>" + " (-10%)";
                        console.log(labelPromoted.innerHTML);
                    }
                    else {
                        labelPromoted.innerHTML = "Total <span class='total-calc-days-responsive'> | " + diffDays + " jours</span>" + " (-10%)";
                    }
                    */

                    labelPromoted.innerHTML = "Total <span class='total-calc-days-responsive'> | " + diffDays + " jours</span>" + " (-10%)";
                }
                else {
                    

                    if(reducInput.value != code) {
                        totalLabel.classList.remove('label-promoted');
                        totalLabel.innerHTML = "Total <span class='total-calc-days-responsive'>| " + diffDays + " jours</span>";

                    } 
                }

                /* !!! TO CHANGE FOR USE WITH THE SEVEN DAY PROMOS !!!
                if(testPromo == true) {
                    totalCalc = Math.round(totalCalc - (totalCalc/100) * 10);
                    totalCalcInput = document.getElementById('edit-totalvalue');

                    totalLabel.classList.add('label-promoted');
                    var labelPromoted = document.querySelector('.label-promoted');

                    labelPromoted.innerHTML = "Total <span class='total-calc-days-responsive'> | " + diffDays + " jours</span>" + " (-10%)";


                } else {
                    totalLabel.classList.remove('label-promoted');
                    totalLabel.innerHTML = "Total <span class='total-calc-days-responsive'>| " + diffDays + " jours</span>";
                }
                */

                totalCalcText.innerHTML = totalCalc + '€';

                totalCalcInput.value = totalCalc;   
                
                totalCautionInput.value = Math.round(totalCalc / 2);

                //console.log(totalCautionInput.value);

                
                
                

                var currentdate = new Date(); 

                currentDateValue.value = (currentdate.getMonth()+1) + "/"
                + currentdate.getDate()  + "/" 
                + currentdate.getFullYear() + " @ "  
                + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
                + currentdate.getSeconds();
            }
            totalAmount();   
            
            formSubmitReservation.addEventListener('click', (e)=>{
                totalAmount();
            });


            // Form - Checkboxes
            Array.from(document.querySelectorAll('.js-webform-checkboxes .form-check')).forEach((formCheckBox) => {
                var formCheckBoxInput = formCheckBox.querySelector('.form-checkbox');
                formCheckBoxInput.closest('.form-check').prepend(formCheckBoxInput);

                var checkboxSubcontainer = document.createElement('div');
                checkboxSubcontainer.classList.add('checkbox-subcontainer');
                formCheckBox.appendChild(checkboxSubcontainer);

                var formCheckboxLabel = formCheckBox.querySelector('.form-check-label');
                var formCheckboxDesc = formCheckBox.querySelector('.description');
                checkboxSubcontainer.append(formCheckboxDesc, formCheckboxLabel);

                formCheckBoxInput.addEventListener("click", optionResume);
                formCheckBoxInput.addEventListener("click", optionPrices);
                formCheckBoxInput.addEventListener("click", totalAmount);
            })

            // Form - Sidebar - Resume Option
            function optionResume() {
                var optionTable = [];
                
                var optionResumeText = document.getElementById('option_resume');
                optionResumeText.innerHTML = "<li>Forfait nettoyage <em>inclut</em></li>";

                var checkboxes = document.querySelectorAll('input[type=checkbox]');

                for (var i = 0; i < checkboxes.length; i++) {
                    if(checkboxes[i].checked) {
                        if (!optionTable.includes(checkboxes[i].value)) {
                            optionTable.push(checkboxes[i].value);

                            var item = document.createElement('li');
            
                            // Set its contents:
                            item.innerHTML = checkboxes[i].value + "<em>" + checkboxes[i].getAttribute("data-subprice") + "€</em>";

                            // Add it to the list:
                            optionResumeText.appendChild(item);
                        }
                    }
                }
            }
            optionResume();  
        }
    }


    // Form - RGPD
    var gdprContainer = document.getElementById('edit-gdpr-container');
    if (typeof(gdprContainer) != 'undefined' && gdprContainer != null) {
        var gdprCheckbox =  document.createElement("span")

        var gdprDesc = gdprContainer.querySelector('.description');

        gdprContainer.querySelector('.form-check-label').append(gdprCheckbox);

        gdprContainer.querySelector('.form-check-label').append(gdprDesc);
    };

    var cglContainer = document.getElementById('edit-cgl-container');
    if (typeof(cglContainer) != 'undefined' && cglContainer != null) {
        var cglCheckbox =  document.createElement("span")

        var cglDesc = cglContainer.querySelector('.description');

        cglContainer.querySelector('.form-check-label').append(cglCheckbox);

        cglContainer.querySelector('.form-check-label').append(cglDesc);
    };
});